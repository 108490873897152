import axios from "axios";
import { API_PATH } from "../config";

export function hitApi(filters, path) {
  return axios.get(path, {
    params: filters,
  });
}

/**
 * Get All Clinics List
 *
 * @param {Object} filters
 * @return {*} Promise
 */
export function getAllClinicsListApi(filters) {
  return axios.get(API_PATH.CLINICS.getAllCLinicsList, {
    params: filters,
  });
}
export function addBulkClinicApi(file) {
  return axios.post(API_PATH.CLINICS.addBulkClinic, file
  );
}
export function addBulkDoctorApi(file) {
  return axios.post(API_PATH.CLINICS.addBulkDoctor, file
  );
}
export function getUploadExcelViewApi(file) {
  debugger
  return axios.post(API_PATH.CLINICS.getUploadExcelView,file
  );
}
/**
 * Add New Clinic
 *
 * @param {Object} filters
 * @return {*} Promise
 */
export function addNewClinicApi(data) {
  return axios.post(API_PATH.CLINICS.addNewCLinic, data, {
    params: data,
  });
}

/**
 * Get Single Clinics Detail
 *
 * @param {Object} filters
 * @return {*} Promise
 */
export function getSingleClinicDetailsApi(clinicId) {
  return axios.get(`${API_PATH.CLINICS.getSingleCLinic}?ClinicId=${clinicId}`);
}
export function getTagsApi(req) {
  const { isGetClinics, AdminId } = req || {};

  // Construct the URL with conditional query parameters
  const url = `${API_PATH.CLINICS.getTags}?isGetClinics=${isGetClinics}${AdminId ? `&AdminId=${AdminId}` : ''
    }`;
  return axios.get(url);
}

/**
 * Get All Clinics List for Select Filter
 *
 * @return {*} Promise
 */
export function getClinicsForSelectApi(isForDHISConfig) {
  return axios.get(
    `${API_PATH.CLINICS.getAllCLinicsList}?PageNumber=1&PageSize=1000&IsForDHISConfig=${isForDHISConfig}`
  );
}
export function adminTallyValidationApi(req) {
  return axios.post(
    `${API_PATH.CLINICS.adminTallyValidation}`, req
  );
}
export function getAllMappingClinicsListApi() {
  return axios.get(
    `${API_PATH.CLINICS.getAllMappingClinicsList}?PageNumber=1&PageSize=1000`
  );
}
export function getAllPolicyGroupsApi() {
  return axios.get(`${API_PATH.PERMISSIONS.getAllPolicyGroups}`);
}
export function getAllDashboardDetailsApi(filterData) {
  return axios.get(`${API_PATH.PERMISSIONS.getAllDashboardDetails}`,
    {
      params: filterData,
    }
  );
}
export function getAllRolesApi() {
  return axios.get(`${API_PATH.PERMISSIONS.getAllRoles}`);
}
export function getPolicesByRoleIdApi(roleId) {
  return axios.get(
    `${API_PATH.PERMISSIONS.getAllPolicyGroupsByGroupId}?PolicyGroupId=${roleId}`
  );
}
export function deleteRoledApi(roleId) {
  return axios.post(`${API_PATH.PERMISSIONS.deleteRole}`, roleId);
}
export function getAllPoliciesApi() {
  return axios.get(`${API_PATH.PERMISSIONS.getAllPolicies}`);
}
export function getDHIS2CredsApi() {
  return axios.get(`${API_PATH.PERMISSIONS.getDHIS2Creds}`);
}
export function getAdministeredLocationTypesApi() {
  return axios.get(`${API_PATH.PERMISSIONS.getAdministeredLocationTypes}`);
}
export function addOrUpdateRoleApi(data) {
  return axios.post(`${API_PATH.PERMISSIONS.addOrUpdatePolicyGroup}`, data);
}
export function updateDHIS2CredsApi(data) {
  return axios.post(`${API_PATH.PERMISSIONS.updateDHIS2Creds}`, data);
}
export function assignPoliciesApi(data) {
  return axios.post(`${API_PATH.PERMISSIONS.assignPolicies}`, data);
}
export function addPolicyGroupApi(data) {
  return axios.post(`${API_PATH.PERMISSIONS.addPolicyGroup}`, data);
}
